/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useRef } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import { SEO } from '@renderbus/common/components'
import { baseLink } from '@renderbus/common/service'
import {
  RCSignFormContainer,
  PageContainer,
  RCStyle,
  RCFormSection,
  RCSignForm,
  RCSignFormTitle,
  RCFormItem,
  RCLabel,
  RCRegisterLabel,
  RCRenderbusSupport,
  BottomLine,
  RCInput,
  RCRadioContainer,
  RCRadio,
  RCCheckbox,
  RCAgreements,
  QrContainer,
  RCButton,
  RCDialogContainer,
  PageFooterMobile,
  RCInputField,
  RCOrgFormItem,
  RCSelectFormItem,
  ErrorWrapper,
  ErrorText,
  RCCheckboxContainer,
  RCFormCheckbox,
} from './rc-form.atom'

import QRImg from '../images/render-contest/qr@2x.png'
import QRImgMb from '../images/render-contest/qrCodeMb.png'

const formItemProps = [
  { id: '#name', type: 'input' },
  { id: '#phone', type: 'input' },
  { id: '#email', type: 'input' },
  { id: '#wechat', type: 'input' },
  { id: '#account', type: 'input' },
  { id: '#organization', type: 'input' },
  { id: '#position', type: 'input' },
  { id: '#info-from', type: 'radio' },
  { id: '#machine', type: 'radio' },
  { id: '#will-contact', type: 'radio' },
  { id: '#care-about', type: 'checkbox' },
  { id: '#checkbox-agree-1', type: 'checkbox' },
  { id: '#checkbox-agree-2', type: 'checkbox' },
  { id: '#checkbox-agree-3', type: 'checkbox' },
]
const infoSourceOptions = [
  'Renderbus相关平台',
  '联想拯救者相关平台',
  '青椒云相关平台',
  'QQ群和微信群',
  '朋友圈',
  'CG世界相关平台',
  'B站相关平台',
]
const infoMachineOptions = ['主流配置i9+4060', '中阶配置i9+4070', '豪华配置i9+4080/4090']
const isWillContactOptions = ['是', '否']
const careAboutOptions = [
  '品牌知名度高',
  '身边亲友推荐',
  '价格对比优势',
  '散热性能优秀',
  '外观炫酷好看',
  '机身轻便易携带',
]
const inputFields = new Map([
  ['name', '姓名'],
  ['email', '邮箱'],
  ['phone', '联系电话'],
  ['wechat', '微信号'],
  ['account', '瑞云账号'],
  ['organization', '所在的公司名/组织机构/学校全称'],
  ['position', '所担任的职务/学院专业全称'],
])
const initForm = {
  name: '',
  phone: '',
  email: '',
  wechat: '',
  account: '',
  organization: '',
  position: '',
  infoFrom: '',
  machine: '',
  isWillContact: '',
  careAbout: [],
}
// 必填
function setRequiredStyle(id, type = 'input') {
  const element = document.querySelector(id)
  if (!element) return
  let isValid = false
  if (type === 'input') {
    isValid = !!element.value.trim()
  }
  if (type === 'checkbox' && id !== '#care-about') {
    isValid = element.checked
  }
  const validateClassName = element.className.replace(' error', '')
  const invalidClassName = validateClassName + ' error'
  element.className = !isValid ? invalidClassName : validateClassName
  return [isValid, validateClassName, invalidClassName]
}
// 对于错误需要展示错误信息
function setErrorBottomStyle(id, isValid) {
  const element = document.querySelector(id + '-error')
  if (!element) return
  const validateClassName = element.className.replace(' error', '')
  const invalidClassName = validateClassName + ' error'
  element.className = !isValid ? invalidClassName : validateClassName
}

function RenderContestSignUp() {
  const [formData, setFormData] = useState({ ...initForm })
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [checkAgree, setCheckAgree] = useState(false)
  const [checkAgree2, setCheckAgree2] = useState(false)
  const [checkAgree3, setCheckAgree3] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailRight, setEmailRight] = useState(true)
  const [phoneRight, setPhoneRight] = useState(true)
  const [accountRight, setAccountRight] = useState(true)
  const [isOther, setIsOther] = useState(false)
  const [isMachineOther, setIsMachineOther] = useState(false)
  const [otherDetail, setOtherDetail] = useState('')
  const [otherMachineDetail, setOtherMachineDetail] = useState('')
  const pageContainer = useRef(null)
  function validateRequired(id, type, value) {
    if (!type) return
    if (type === 'input') {
      const result = setRequiredStyle(id, type)
      setErrorBottomStyle(id, result[0])
      return result[0]
    }
    if (type === 'checkbox') {
      if (id === '#care-about') {
        const val = value || formData.careAbout
        setErrorBottomStyle(id, Boolean(val.length > 0))
        return Boolean(val.length > 0)
      } else {
        return setRequiredStyle(id, type)[0]
      }
    }
  }
  function setFormDataField(key, value, id) {
    setFormData({
      ...formData,
      [key]: value,
    })
    const filterItem = formItemProps.find((item) => item.id === id)
    const type = filterItem ? filterItem.type : ''
    validateRequired(id, type, value)
    id === '#email' && validateEmail(false)
    id === '#phone' && validatePhone()
    id === '#account' && validateAccount()
    if (!id || id === '#info-from' || id === '#machine' || id === '#will-contact')
      validateRadio(id, value)
  }
  function handleIKnow() {
    setIsDialogVisible(false)
    if (submitSuccess) {
      window.location.href = '/rc2024.html'
    }
  }
  function validateForm() {
    const result = formItemProps.map(({ id, type }) => {
      if (id === '#info-from') {
        return isOther ? validateOtherInput(otherDetail, id) : validateRadio(id)
      }
      if (id === '#machine') {
        return isMachineOther ? validateOtherInput(otherMachineDetail, id) : validateRadio(id)
      }
      if (id === '#will-contact') {
        return validateRadio(id)
      }
      return validateRequired(id, type)
    })
    return result.every((r) => r)
  }
  function validateRadio(id, value) {
    const radioMap = new Map([
      ['#info-from', 'infoFrom'],
      ['#machine', 'machine'],
      ['#will-contact', 'isWillContact'],
    ])
    const val = value || formData[radioMap.get(id)]
    const checkedOther = val === '其他'
    if (id === '#info-from') {
      setIsOther(checkedOther)
      setErrorBottomStyle(id, checkedOther ? Boolean(otherDetail) : Boolean(val))
    } else if (id === '#machine') {
      setIsMachineOther(checkedOther)
      setErrorBottomStyle(id, checkedOther ? Boolean(otherMachineDetail) : Boolean(val))
    } else {
      setErrorBottomStyle(id, Boolean(val))
    }
    return Boolean(val)
  }
  function validateOtherInput(val, id) {
    if (id === '#info-from') {
      setOtherDetail(val)
    } else {
      setOtherMachineDetail(val)
    }
    setErrorBottomStyle(id, Boolean(val))
    return Boolean(val)
  }

  function validateInputReg(id, reg) {
    const result = setRequiredStyle(id, 'input')
    const inputElement = document.querySelector(id)
    const isValid = reg.test(inputElement.value) && !!inputElement.value
    inputElement.className = !isValid ? result[2] : result[1]
    setErrorBottomStyle(id, isValid)
    return !inputElement.value || reg.test(inputElement.value)
  }
  function validateEmail(isError = false) {
    const result = setRequiredStyle('#email', 'input')
    const inputElement = document.querySelector('#email')
    inputElement.className = isError ? result[2] : result[1]
    setEmailError(isError)
    if (isError) return
    const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g
    const isRight = validateInputReg('#email', emailReg)
    setEmailRight(!inputElement.value || isRight)
  }
  function validatePhone() {
    const phoneReg = /^1[3456789]\d{9}$/
    const isRight = validateInputReg('#phone', phoneReg)
    setPhoneRight(isRight)
  }
  function validateAccount() {
    const accountReg = /^(?=.*[a-zA-Z])[a-zA-Z0-9_]{6,20}$/
    const isRight = validateInputReg('#account', accountReg)
    setAccountRight(isRight)
  }

  function handleSubmit() {
    if (submiting) return
    if (!validateForm()) return
    Object.keys(formData).forEach(function (key) {
      if (formData[key] instanceof Array === false) {
        formData[key] = formData[key].trim()
      }
    })
    setSubmiting(true)
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/rc-03/sign-for-contest',
      data: {
        ...formData,
        infoFrom: isOther ? otherDetail : formData.infoFrom,
        machine: isMachineOther ? otherMachineDetail : formData.machine,
        careAbout: formData.careAbout.toString(),
      },
    })
      .then((response) => {
        const { data } = response
        setSubmitSuccess(data.code === 200)
        setIsDialogVisible(true)
      })
      .catch((err) => {
        if (err.response.status === 410) {
          validateEmail(true)
        }
        setIsDialogVisible(false)
        setSubmitSuccess(err.response.status !== 410)
      })
      .finally(() => {
        setSubmiting(false)
      })
  }

  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
        </script>
      </Helmet>
      <SEO
        title='渲染大赛参赛报名表-瑞云3D渲染动画创作大赛'
        keywords='参赛报名表, 渲染大赛'
        description='3D渲染动画大赛，汇聚CG领域优秀艺术家，一起角逐大奖，赢专业评委点评，媒体曝光，为自己代言！一年一度渲染大赛现已开启正式报名，欢迎投稿参赛~'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/banner.png'
        sharePostSlug='rc-form.html'
      />
      <PageContainer ref={pageContainer}>
        <RCFormSection>
          <RCSignFormContainer>
            <RCSignFormTitle>参赛报名表</RCSignFormTitle>
            <RCSignForm>
              <RCFormItem>
                <RCLabel>您的姓名:</RCLabel>
                <RCInputField>
                  <RCInput
                    maxLength={5}
                    value={formData.name}
                    id='name'
                    onChange={(e) => setFormDataField('name', e.target.value, '#name')}
                  />
                  <ErrorText id='name-error'>请输入{inputFields.get('name')}</ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem style={{ marginBottom: emailError && '10px' }}>
                <RCLabel>您的邮箱:</RCLabel>
                <RCInputField>
                  <RCInput
                    value={formData.email}
                    placeholder='接收作品提交邮件'
                    id='email'
                    onChange={(e) => setFormDataField('email', e.target.value, '#email')}
                  />
                  {emailError && (
                    <ErrorWrapper>
                      <span>!</span>
                      每个邮箱只能报名一次
                    </ErrorWrapper>
                  )}
                  <ErrorText id='email-error'>
                    请输入{!emailRight && '正确的'}
                    {inputFields.get('email')}
                  </ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem>
                <RCLabel>您的电话:</RCLabel>
                <RCInputField>
                  <RCInput
                    type='tel'
                    value={formData.phone}
                    id='phone'
                    onChange={(e) => setFormDataField('phone', e.target.value, '#phone')}
                  />
                  <ErrorText id='phone-error'>
                    请输入{!phoneRight && '正确的'}
                    {inputFields.get('phone')}
                  </ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem>
                <RCLabel>您的微信号:</RCLabel>
                <RCInputField>
                  <RCInput
                    value={formData.wechat}
                    placeholder='非微信昵称'
                    id='wechat'
                    onChange={(e) => setFormDataField('wechat', e.target.value, '#wechat')}
                  />
                  <ErrorText id='wechat-error'>请输入{inputFields.get('wechat')}</ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem>
                <div className='account-form-item'>
                  <RCLabel>您的瑞云账号:</RCLabel>
                  <RCInputField>
                    <RCInput
                      className='account-input'
                      value={formData.account}
                      placeholder='(非UUID)'
                      id='account'
                      onChange={(e) => setFormDataField('account', e.target.value, '#account')}
                    />
                    <ErrorText id='account-error'>
                      请输入{!accountRight && '正确的'}
                      {inputFields.get('account')}
                    </ErrorText>
                    <RCRenderbusSupport isMobile>
                      本次大赛由Renderbus瑞云渲染提供免费云渲染支持
                    </RCRenderbusSupport>
                    <RCRenderbusSupport>
                      （本次大赛由Renderbus瑞云渲染提供免费云渲染支持）
                    </RCRenderbusSupport>
                    <RCRegisterLabel isMobile>
                      我还没有账号，点击
                      <a href={`${baseLink()}/sso/register?utm_source=renderchallenge`}>此处</a>
                      立即注册
                    </RCRegisterLabel>
                  </RCInputField>
                </div>
                <RCRegisterLabel>
                  我还没有账号，点击
                  <a href={`${baseLink()}/sso/register?utm_source=renderchallenge`}>此处</a>
                  立即注册
                </RCRegisterLabel>
              </RCFormItem>
              <BottomLine />
              <RCOrgFormItem className='organization-form-item'>
                <RCLabel>您所在的公司名/组织机构/学校全称:</RCLabel>
                <RCInputField>
                  <RCInput
                    value={formData.organization}
                    id='organization'
                    onChange={(e) =>
                      setFormDataField('organization', e.target.value, '#organization')
                    }
                  />
                  <ErrorText id='organization-error'>
                    请输入{inputFields.get('organization')}
                  </ErrorText>
                </RCInputField>
              </RCOrgFormItem>
              <RCOrgFormItem>
                <RCLabel>您所担任的职务/学院专业全称:</RCLabel>
                <RCInputField>
                  <RCInput
                    value={formData.position}
                    id='position'
                    onChange={(e) => setFormDataField('position', e.target.value, '#position')}
                  />
                  <ErrorText id='position-error'>请输入{inputFields.get('position')}</ErrorText>
                </RCInputField>
              </RCOrgFormItem>
              <BottomLine />
              <RCSelectFormItem>
                <RCLabel className='long-question'>您是从哪里得知本次大赛？（单选）</RCLabel>
                <RCRadioContainer>
                  {infoSourceOptions.map((source) => (
                    <div key={source}>
                      <RCRadio
                        type='radio'
                        value={source}
                        checked={source === formData.infoFrom}
                        onChange={(e) => setFormDataField('infoFrom', source, '#info-from')}
                      />
                      <span>{source}</span>
                    </div>
                  ))}
                  <div>
                    <RCRadio
                      type='radio'
                      value='其他'
                      checked={formData.infoFrom && !infoSourceOptions.includes(formData.infoFrom)}
                      onChange={(e) => setFormDataField('infoFrom', '其他', '#info-from')}
                    />
                    <span>其他：</span>
                    <RCInput
                      className='other-input'
                      id='info-from'
                      onChange={(e) => validateOtherInput(e.target.value, '#info-from')}
                    />
                  </div>
                </RCRadioContainer>
                <ErrorText id='info-from-error'>
                  {isOther ? '请补充其他途径' : '请选择途径'}
                </ErrorText>
              </RCSelectFormItem>
              <BottomLine />
              <RCSelectFormItem>
                <RCLabel className='long-question'>你正在使用什么配置的机器？（单选）</RCLabel>
                <RCRadioContainer>
                  {infoMachineOptions.map((item) => (
                    <div key={item}>
                      <RCRadio
                        type='radio'
                        value={item}
                        checked={item === formData.machine}
                        onChange={(e) => setFormDataField('machine', item, '#machine')}
                      />
                      <span>{item}</span>
                    </div>
                  ))}
                  <div>
                    <RCRadio
                      type='radio'
                      value='其他'
                      checked={formData.machine && !infoMachineOptions.includes(formData.machine)}
                      onChange={(e) => setFormDataField('machine', '其他', '#machine')}
                    />
                    <span>其他：</span>
                    <RCInput
                      className='other-input'
                      id='machine'
                      onChange={(e) => validateOtherInput(e.target.value, '#machine')}
                    />
                  </div>
                </RCRadioContainer>
                <ErrorText id='machine-error'>
                  {isMachineOther ? '请补充其他配置的机器' : '请选择配置的机器'}
                </ErrorText>
              </RCSelectFormItem>
              <BottomLine />
              <RCSelectFormItem>
                <RCLabel className='long-question'>
                  如果买电竞本进行三维创作渲染，同等配置您更关心以下哪些方面？（可多选）？
                </RCLabel>
                <RCCheckboxContainer>
                  {careAboutOptions.map((item) => (
                    <div key={item}>
                      <RCFormCheckbox
                        type='checkbox'
                        // id='care-about'
                        value={item}
                        checked={formData.careAbout.includes(item)}
                        onChange={(e) =>
                          setFormDataField(
                            'careAbout',
                            e.target.checked
                              ? [...formData.careAbout, item]
                              : formData.careAbout.filter((value) => value !== item),
                            '#care-about',
                          )
                        }
                      />
                      <span>{item}</span>
                    </div>
                  ))}
                </RCCheckboxContainer>
                <ErrorText id='care-about-error'>请选择</ErrorText>
              </RCSelectFormItem>
              <BottomLine />
              <RCSelectFormItem>
                <RCLabel className='long-question'>
                  您是否愿意与联想拯救者取得联系，参加以后的作品共创/相关品牌活动？
                </RCLabel>
                <RCRadioContainer>
                  {isWillContactOptions.map((item) => (
                    <div key={item}>
                      <RCRadio
                        type='radio'
                        value={item}
                        checked={item === formData.isWillContact}
                        onChange={(e) => setFormDataField('isWillContact', item, '#will-contact')}
                      />
                      <span>{item}</span>
                    </div>
                  ))}
                </RCRadioContainer>
                <ErrorText id='will-contact-error'>请选择</ErrorText>
              </RCSelectFormItem>
              <BottomLine />
              <RCSelectFormItem>
                <RCAgreements>
                  <div>
                    <RCCheckbox
                      id='checkbox-agree-3'
                      checked={checkAgree3}
                      onClick={() => setCheckAgree3(!checkAgree3)}
                    />
                    <span>
                      本人知悉且同意Renderbus瑞云渲染基于调研、活动组织的目的对本人的上述信息的收集和处理，并已经阅读并同意Renderbus瑞云渲染的
                      <a
                        href='https://www.renderbus.com/support-new/privacy-policy/'
                        target='_blank'
                      >
                        隐私政策
                      </a>
                      。
                    </span>
                  </div>
                  <div>
                    <RCCheckbox
                      id='checkbox-agree-1'
                      checked={checkAgree}
                      onClick={() => setCheckAgree(!checkAgree)}
                    />
                    <span>
                      本人已知悉且同意诚信参赛，不弄虚作假，不恶意侵占比赛资源，若有违反，主办方将追回侵占资源费用且追究法律责任。
                    </span>
                  </div>
                  <div>
                    <RCCheckbox
                      id='checkbox-agree-2'
                      checked={checkAgree2}
                      onClick={() => setCheckAgree2(!checkAgree2)}
                    />
                    <span>
                      本人已知悉所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权。主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，主办方及合作方有权将用于市场活动的作品加入赛事水印，参赛者保留对其作品的著作权。获奖者将获邀参与由主办方及合作方举办的相关市场推广活动。作品一经提交，将视为参赛者同意并遵守比赛相关规定。
                    </span>
                  </div>
                </RCAgreements>
              </RCSelectFormItem>
            </RCSignForm>
            <RCButton disabled={true} type='button' id='rc-form'>
              {submiting ? '提交中' : '提交报名表'}
            </RCButton>
          </RCSignFormContainer>
        </RCFormSection>
        <QrContainer>
          <p>
            赛事已截稿
            <br />
            扫码了解最新动态
          </p>
          <img src={QRImg} alt='' />
          <p>
            微信号
            <br />
            rayvision1
          </p>
        </QrContainer>
        {/* <PageFooter>
          <p>
            <img src={PoliceIcon} alt='' />© 2022
            <a href='https://www.rayvision.com/' rel='nofollow'>
              深圳市瑞云科技股份有限公司
            </a>
            <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
              粤ICP备12028569号
            </a>
          </p>
          <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
        </PageFooter> */}
        <PageFooterMobile>
          <img src={QRImgMb} alt='二维码' />
          <div>
            <div>赛事已截稿，扫码了解最新动态</div>
          </div>
        </PageFooterMobile>
        <RCDialogContainer isActive={isDialogVisible}>
          <div className='content'>
            <img src={QRImg} alt='' />
            <div className='text'>
              <div>
                <p>{submitSuccess ? '恭喜你报名表提交成功!' : '糟糕!报名表提交失败了!'}</p>
                <span>
                  {submitSuccess
                    ? '加小助手进赛事交流群，随时留意赛事信息。'
                    : '可能是因为网络原因，请您检查网络或者加大赛小助手微信反馈！'}
                </span>
              </div>
              <button onClick={handleIKnow}>我知道了</button>
            </div>
          </div>
        </RCDialogContainer>
      </PageContainer>
      <RCStyle />
    </React.Fragment>
  )
}

export default RenderContestSignUp
